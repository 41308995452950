import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button, ButtonGroup } from "clutch/src/Button/Button.js";
import { Card } from "clutch/src/Card/Card.js";

import type { MatchCore } from "@/game-fortnite/models/game-events.mjs";
import AccountIcon from "@/inline-assets/avatar-account.svg";
import TargetIcon from "@/inline-assets/fn-kills.svg";
import TeamElimsIcon from "@/inline-assets/fortnite-team-elims.svg";

enum FeedTypes {
  Eliminations = "Eliminations",
  Knocks = "Knocks",
  Rebooted = "Rebooted",
  StormRevealed = "StormRevealed",
}

type FeedItemType = {
  type: FeedTypes;
  prey: string;
  predator: string;
  isPlayerPredator: boolean;
  isPlayerPrey: boolean;
  isTeammate: boolean;
  // timestamp: Date; // Todo: When RE sends other FeedTypes, we will need RE to send timestamps of all feed items
};

export default function Feed({
  profileId,
  match,
}: {
  profileId: string | undefined;
  match: MatchCore;
}) {
  const { t } = useTranslation();
  const [isLobby, setLobby] = useState(true);
  const feed = useMemo<Array<FeedItemType>>(() => {
    const result = [];
    // Elimination feed
    if (match.kills && match.matchPlayers && match.localPlayer) {
      for (const kill of match.kills) {
        // My Feed
        if (!isLobby && kill.killerId !== profileId) continue;
        // Lobby Feed
        const isPlayerPredator = kill.killerId === profileId;
        const isPlayerPrey = kill.killedId === profileId;
        const prey = isPlayerPrey
          ? match.localPlayer
          : match.matchPlayers[kill.killedId];
        const predator = isPlayerPredator
          ? match.localPlayer
          : match.matchPlayers[kill.killerId];
        if (!prey || !predator) continue;
        const predatorName = predator.anonymous
          ? t("fortnite:match.anonymous", "Anonymous")
          : predator.name;
        const preyName = prey.anonymous
          ? t("fortnite:match.anonymous", "Anonymous")
          : prey.name;
        const teammate =
          Array.isArray(match.localPlayer.teammates) &&
          match.localPlayer.teammates.includes(kill.killerId) &&
          match.matchPlayers[kill.killerId];
        result.push({
          type: FeedTypes.Eliminations,
          prey: preyName,
          predator: predatorName,
          isPlayerPredator: predator.anonymous ? false : isPlayerPredator,
          isPlayerPrey: prey.anonymous ? false : isPlayerPrey,
          isTeammate: predator?.anonymous ? false : Boolean(teammate),
        });
      }
    }
    // Knocks
    // ..
    // Rebooted
    // ..
    // Storm Revealed
    // ..
    return result.reverse();
  }, [
    isLobby,
    match.kills,
    match.localPlayer,
    match.matchPlayers,
    profileId,
    t,
  ]);
  return (
    <Card
      padding="var(--sp-4)"
      className="card-frame card-contents flex column gap-2"
    >
      {!!match.matchLive && (
        <div className="flex justify-center align-center gap-4">
          <div className="flex align-center color-shade1 gap-1">
            <AccountIcon />
            {match.matchLive.playersLeft}
          </div>
          <div className="flex align-center color-shade1 gap-1">
            <TargetIcon />
            {match.matchLive.killScore}
          </div>
          {Array.isArray(match.localPlayer?.teammates) &&
            !!match.localPlayer.teammates.length && (
              <div className="flex align-center color-shade1 gap-1">
                <TeamElimsIcon />
                {match.matchLive.teamKillScore}
              </div>
            )}
        </div>
      )}
      <ButtonGroup block>
        <Button
          onClick={() => setLobby(false)}
          active={!isLobby}
          disabled={match.localPlayer?.anonymous}
        >
          {t("fortnite:match.myFeed", "My Feed")}
        </Button>
        <Button onClick={() => setLobby(true)} active={isLobby}>
          {t("fortnite.match.lobbyFeed", "Lobby Feed")}
        </Button>
      </ButtonGroup>
      {feed.length ? (
        <Feeder className="flex">
          {feed.map((i, idx) =>
            React.createElement(FeedSwitch, Object.assign(i, { key: idx })),
          )}
        </Feeder>
      ) : (
        <FeedItem>
          {t("fortnite.match.waitForFeedEvents", "Waiting for events")}
        </FeedItem>
      )}
    </Card>
  );
}

function getColorClasses(playerType: "isPlayer" | "isTeam"): {
  highlight: string;
  color: string;
} {
  switch (playerType) {
    case "isPlayer": {
      return {
        highlight: "highlight highlight-gold",
        color: "var(--rank-gold)",
      };
    }
    case "isTeam": {
      return {
        highlight: "highlight highlight-turq",
        color: "var(--turq)",
      };
    }
    default: {
      return {
        highlight: undefined,
        color: undefined,
      };
    }
  }
}

function FeedSwitch({
  type,
  prey,
  predator,
  isPlayerPrey,
  isPlayerPredator,
  isTeammate,
}: FeedItemType) {
  const { t } = useTranslation();
  const playerType =
    isPlayerPredator || isPlayerPrey
      ? "isPlayer"
      : isTeammate
        ? "isTeam"
        : undefined;
  const { highlight, color } = getColorClasses(playerType);
  switch (type) {
    case FeedTypes.Eliminations: {
      return (
        <FeedItem>
          {!!playerType && (
            <>
              <div className={highlight} />
              <div className="indicator" style={{ backgroundColor: color }} />
            </>
          )}
          <span style={{ color }}>
            {isPlayerPredator ? t("common:you", "You") : predator}
          </span>
          <TargetIcon
            width={18}
            height={18}
            style={{
              color:
                isPlayerPrey || isPlayerPredator
                  ? "var(--shade0)"
                  : "var(--shade1)",
            }}
          />
          <span style={{ color }}>
            {isPlayerPrey ? t("common:you", "You") : prey}
          </span>
        </FeedItem>
      );
    }
    default: {
      return null;
    }
  }
}

const Feeder = styled("div")`
  gap: var(--sp-px);
  max-height: calc(2 * var(--sp-48));
  overflow: hidden;
  overflow-y: scroll;
  flex-direction: column;
`;
const FeedItem = styled("div")`
  padding: var(--sp-2) var(--sp-4);
  background-color: var(--shade3-15);
  border-radius: var(--br);
  color: var(--shade1);
  font-size: var(--sp-3_5);
  display: flex;
  align-items: center;
  gap: var(--sp-2);
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  white-space: nowrap;
  > span {
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > svg {
    width: var(--sp-4_5);
    height: var(--sp-4_5);
    color: var(--shade0);
    flex-shrink: 0;
  }
  > .highlight {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  > .highlight-gold {
    background: rgb(243, 198, 39);
    background: -moz-linear-gradient(
      90deg,
      rgba(243, 198, 39, 0.10270045518207283) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(243, 198, 39, 0.10270045518207283) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(243, 198, 39, 0.10270045518207283) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f3c627",endColorstr="#000000",GradientType=1);
  }
  > .highlight-turq {
    background: rgb(48, 217, 212);
    background: -moz-linear-gradient(
      90deg,
      rgba(48, 217, 212, 0.0970982142857143) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(48, 217, 212, 0.0970982142857143) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(48, 217, 212, 0.0970982142857143) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#30d9d4",endColorstr="#000000",GradientType=1);
  }
  > .indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3px;
  }
`;
